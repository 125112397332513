export default async (target: HTMLAnchorElement) => {
  if (target.target.toLowerCase() === '_self' || !target.target) {
    const url = new URL(target.href)
    if (window.location.origin === url.origin) {
      let path = `${url.pathname}`
      if (url.search) path += `?${url.search}`
      if (url.hash) path += `#${url.hash}`
      const router = useRouter()
      await navigateTo(`${url.pathname}${url.search}${url.hash}`)
    } else {
      await navigateTo(target.href, { external: true })
    }
  } else window.open(target.href, target.target)
}
